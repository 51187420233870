<template>
  <v-container>
    <h2>Baato services status dashboard</h2>
    <v-container v-if="loading">
      <v-row>
        <v-col class="loader-text text-subtitle-1 text-center" cols="12">
          Gathering service status information
        </v-col>
        <v-col cols="4" />
        <v-col cols="4">
          <v-progress-linear
            stream
            class="loader"
            color="#16a085"
            buffer-value="0"
            indeterminate
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>

    <v-row v-if="!loading">
      <v-col class="col-md-9">
        <h1
          :class="
            areAllServiceFunctional()
              ? 'fully-functional'
              : 'fully-not-functional'
          "
        >
          {{
            areAllServiceFunctional()
              ? "All services are fully functional"
              : "Some of the services are affected"
          }}
        </h1>

        <v-row style="margin-top: 0px">
          <v-col
            v-if="status"
            :class="
              status.incidents.filter(
                (incident) => incident.status != 'RESOLVED'
              ).length
                ? 'alert-banner'
                : 'normal-banner'
            "
          >
            <strong style="font-size: 80px">
              {{ getActiveIncidents() }}
            </strong>

            <br />

            active reported incident(s)
          </v-col>
          <v-col
            v-if="status"
            :class="
              status.maintainances.filter(
                (maintainance) => maintainance.status != 'RESOLVED'
              ).length
                ? 'alert-banner'
                : 'normal-banner'
            "
          >
            <strong style="font-size: 80px">{{
              getActiveMaintainances()
            }}</strong>
            <br />
            active maintainance(s)
          </v-col>
        </v-row>

        <v-row style="margin-top: 30px">
          <v-col>
            Services status
            <v-simple-table style="margin-top: 20px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="service in status.services" :key="service.label">
                    <td>{{ service.label }}</td>
                    <td :class="service.status ? 'functional' : 'affected'">
                      <strong>
                        {{ service.status ? "Functional" : "Affected" }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row style="margin-top: 30px">
          <v-col>
            Scheduled maintainances
            <v-simple-table style="margin-top: 20px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Maintainance</th>
                    <th>Start date and time</th>
                    <th>End date and time</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="maintainance in status.maintainances"
                    :key="maintainance.maintainance"
                  >
                    <td>
                      {{ maintainance.maintainance }}
                    </td>
                    <td>
                      {{ getDateTime(maintainance.start_date_time) }}
                    </td>
                    <td>
                      {{ getDateTime(maintainance.end_date_time) }}
                    </td>
                    <td>{{ maintainance.description }}</td>
                    <td>{{ maintainance.status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row style="margin-top: 30px">
          <v-col>
            Incidents
            <v-simple-table style="margin-top: 20px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Incident</th>
                    <th>Start date and time</th>
                    <th>End date and time</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="incident in status.incidents"
                    :key="incident.incident"
                  >
                    <td>
                      {{ incident.incident }}
                    </td>
                    <td>
                      {{ getDateTime(incident.start_date_time) }}
                    </td>
                    <td>
                      {{ getDateTime(incident.end_date_time) }}
                    </td>
                    <td>{{ incident.description }}</td>
                    <td>{{ incident.status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="status" class="col-md-3">
        <v-container style="margin-top: 35px">
          Service regions
          <hr />
          <v-row v-for="region in status.serviceRegions" :key="region.region">
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> {{ region.region }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon
                      medium
                      v-bind:color="region.status ? '#5AC3AE' : '#e74c3c'"
                    >
                      {{
                        region.status ? "mdi-check-circle" : "mdi-close-circle"
                      }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>


<style>
@import "./styles.css";
</style>


<script>
import Moment from "moment";

export default {
  name: "App",

  components: {},

  data: () => ({
    status: null,
    loading: true,
    pollingInterval: 15 * 1000, // in seconds
  }),
  methods: {
    moment: function (dateString) {
      return dateString ? Moment(dateString) : Moment();
    },
    areAllServiceFunctional() {
      if (this.status) {
        let allServicesFunctional = true;
        this.status.services.forEach((service) => {
          if (!service.status) allServicesFunctional = false;
        });
        return allServicesFunctional;
      } else {
        return false;
      }
    },
    getActiveMaintainances() {
      return this.status.maintainances.filter((maintainance) => {
        return maintainance.status == "ACTIVE";
      }).length;
    },
    getActiveIncidents() {
      return this.status.incidents.filter((incident) => {
        return incident.status == "ACTIVE";
      }).length;
    },
    getDateTime(dateTimeString) {
      return this.moment(dateTimeString).format("MMM DD YYYY, h:mm a");
    },
    getStatusInformation() {
      async function fetchStatus() {
        // const response = await fetch("http://localhost:3763/api/v1/status");
        const response = await fetch("/api/v1/status");
        return await response.json();
      }
      fetchStatus().then((data) => {
        this.status = data.status;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getStatusInformation();
    setInterval(() => {
      this.getStatusInformation();
    }, this.pollingInterval);
  },
};
</script>
