<template>
  <v-app>
    <v-main style="background: #2b2b2b; font-family: 'Manrope', sans-serif">
      <Status />
    </v-main>
    <v-footer padless>
      <v-col class="text-right">
        <strong>
          <a rel="noreferrer noopener" href="https://baato.io" target="_blank">
            <img src="@/assets/baato2.png" width="80" /> </a
        ></strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Status from "./components/Status";

export default {
  name: "App",

  components: {
    Status,
  },

  data: () => ({
    darkMode: true,
  }),
  methods: {
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    },
  },
  mounted() {
    this.$vuetify.theme.dark = true;
  },
  computed: {
    switchLabel: function () {
      return this.darkMode ? "light" : "dark";
    },
  },
};
</script>
